<template>
  <div :class="{ 'inline-flex': !block, 'flex': block }" class="border items-stretch shadow rounded px-2 py-2 my-2">
    <div :class="descr.iconClasses" class="flex items-center border-l-4 rounded-l text-xl px-2">
      <Icon :i="descr.iconName" />
    </div>
    <div class="pr-2 flex-grow">
      <div class="text-gray-900 font-bold">
        <slot name="header" />
      </div>

      <div class="text-gray-600">
        <slot />
      </div>
    </div>

    <div v-if="loading" class="flex items-center pl-4 pr-3 text-2xl text-gray-600">
      <Icon i="spinner9" class="animate-spin" />
    </div>
    <template v-else>
      <NuxtLink
        v-if="to"
        :to="to"
        :class="descr.actionClasses"
        class="base-action"
      >
        <slot name="action">
          &rarr;
        </slot>
      </NuxtLink>
      <a
        v-else-if="link"
        :href="link"
        target="_blank"
        rel="noopener"
        :class="descr.actionClasses"
        class="base-action"
        @click="$emit('click', $event)"
      >
        <slot name="action">
          &rarr;
        </slot>
      </a>
      <button
        v-else-if="!!$slots.action"
        :class="descr.actionClasses"
        class="base-action"
        @click="$emit('click', $event)"
      >
        <slot name="action">
          &rarr;
        </slot>
      </button>
    </template>
  </div>
</template>

<script>
import Icon from './Icon.vue'
const TYPEMAP = {
  warning: {
    iconName: 'notification',
    iconClasses: ['border-yellow-400 text-yellow-400'],
    actionClasses: ['bg-yellow-100', 'hover:bg-yellow-200', 'text-yellow-900']
  },
  info: {
    iconName: 'info',
    iconClasses: ['border-blue-400 text-blue-400'],
    actionClasses: ['bg-blue-100', 'hover:bg-blue-200', 'text-blue-900']
  },
  error: {
    iconName: 'cancel-circle',
    iconClasses: ['border-red-400 text-red-400'],
    actionClasses: ['bg-red-100', 'hover:bg-red-200', 'text-red-900']
  }
}

export default {
  components: { Icon },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    to: {
      type: [String, Object],
      required: false,
      default: null
    },
    link: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    descr () {
      return TYPEMAP[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
.base-action {
  @apply flex items-center hover:shadow rounded px-2 ml-5;
}
</style>
