<template>
  <div class="max-w-screen-sm">
    <div v-if="!busy" class="flex flex-col items-center">
      <h1 class="text-2xl font-bold">
        Tu veux rejoindre l'Alcôve ?
      </h1>
      <h2 class="text-gray-400">
        Viens. On a des chats.
      </h2>
      <button class="self-stretch flex mt-4 mb-6 mx-2 overflow-hidden rounded ring-purple-300 transition hover:ring-4 hover:bg-purple-300 text-white" @click="performLogin">
        <span class="p-3 bg-purple-600">
          <Icon i="twitch" />
        </span>
        <div class="p-3 bg-purple-500 text-center flex-grow">
          Se connecter avec Twitch
        </div>
      </button>
      <NuxtLink to="/">
        Retour
      </NuxtLink>
    </div>
    <div v-else-if="!error" class="flex justify-center">
      <AlertBox type="info" loading class="mx-2">
        <template #header>
          Ça vient...
        </template>
        <template #default>
          Alkov est en train de se connecter directement à toi <i class="italic">(si si !)</i>
        </template>
      </AlertBox>
    </div>
    <div v-else class="flex justify-center">
      <AlertBox type="error" class="mx-2" @click="retryLogin">
        <template #header>
          Ouch
        </template>
        <template #default>
          Une erreur est survenue.
        </template>
        <template #action>
          Réessayer
        </template>
      </AlertBox>
    </div>
  </div>
</template>

<script>
import AlertBox from '~/components/AlertBox.vue'
import Icon from '~/components/Icon.vue'
export default {
  components: { Icon, AlertBox },
  head: {
    title: 'Connexion'
  },
  computed: {
    busy () {
      return this.$store.state.auth.busy || (this.$route.query.code !== undefined)
    },
    error () {
      return this.$auth.error
    }
  },
  methods: {
    async performLogin () {
      await this.$auth.loginWith('twitch')
    },
    async retryLogin () {
      this.$auth.error = null
      this.$auth.reset()

      await this.$router.push('/login')
    }
  }
}
</script>
